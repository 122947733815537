<template>
  <v-alert v-if="errors.length > 0" :color="color" type="error">
    <div v-for="(item, index) in errors" :key="index" class="gap-flex">
      <span>Linha {{ item.line }} - {{ item.error }}</span>
      <detail-error-dialog v-if="item.details" :error="item" />
    </div>
  </v-alert>
</template>

<script>
export default {
  components: {
    DetailErrorDialog: () => import('@/modules/industry/components/DetailErrorDialog.vue')
  },
  props: {
    errors: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: 'red'
    }
  }
}
</script>
